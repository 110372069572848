export default class CurrentUser {
    static get() {
        let user = localStorage.getItem("user")
        user = JSON.parse(user)
        return user
    }
    static set(user) {
        localStorage.setItem("user", JSON.stringify(user))
    }
    static remove() {
        localStorage.removeItem("user")
    }
}
