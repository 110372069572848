import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../Component/AuthProvider";
import { alluser, deleteuser, edituser } from "../api";
import CircleLoader from "../Component/CircleLoader";
import { useNavigate } from "react-router-dom";
import {
  DotsHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import FloatNotification from "../Component/FloatNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AdminHome = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [allUser, setAllUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFloatNotification, setShowFloatNotification] = useState({
    show: false,
    title: "Success",
    type: "success",
    description: "",
  });

  const getUsers = () => {
    if (user.accessToken) {
      setLoading(true);
      fetch(alluser, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.users) {
            setAllUser(data.users);
            setLoading(false);
          }
        });
    }
  };

  const changePassword = (_id) => {
    if (user.accessToken) {
      try {
        fetch(edituser, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id,
            password: "lvbkarna@123",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "failed") {
              setShowFloatNotification({
                show: true,
                type: "failure",
                title: "Failed",
                description: data.message,
              });
            } else {
              setShowFloatNotification({
                show: true,
                type: "success",
                title: "Success !",
                description: data.message,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteUser = (_id) => {
    if (user.accessToken) {
      try {
        fetch(deleteuser, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ _id: _id }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "failed") {
              setShowFloatNotification({
                show: true,
                type: "failure",
                title: "Failed",
                description: data.message,
              });
            } else {
              setShowFloatNotification({
                show: true,
                type: "success",
                title: "Success !",
                description: data.message,
              });
              getUsers();
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="mt-2 mx-3">
      <div className="flex sm:flex-row flex-col justify-between mx-1 sm:mx-1 px-1 sm:px-1 ">
        <h1 className="sm:mb-0 mb-3 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          List of Users
        </h1>
        <div>
          <button
            onClick={() => navigate("signup")}
            className="text-white sm:ml-5 ml-0 mx-5 bg-gray-600 hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Add New User
          </button>
          <button
            onClick={() => logout()}
            className="text-white bg-gray-600 hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Logout
          </button>
        </div>
      </div>
      {loading ? (
        <CircleLoader />
      ) : (
        <div className="mx-1 sm:mx-1 px-1 sm:px-1 py-4 ">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Mobile
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Company Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allUser.map((v, index) => (
                  <tr key={v._id}>
                    <td className="py-5 border-b border-gray-200 bg-white text-sm ">
                      <div className="flex">
                        <div className="ml-3">
                          <button
                            onClick={() => navigate(`/details?id=${v._id}`)}
                          >
                            <p className="text-gray-900 whitespace-no-wrap">
                              {v.name}
                            </p>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {v.mobile}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {v.company_name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {v.category}
                      </p>
                    </td>
                    <td className="relative whitespace-nowrap px-5 py-5 border-b text-left text-sm font-medium sm:pr-6">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="z-10 border-2 border-gray-200 p-2 rounded-md flex items-center text-gray-400 hover:text-primaryDark hover:border-primaryDark">
                            <span className="sr-only">Open options</span>
                            <DotsHorizontalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 z-20 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1 w-full">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      changePassword(v._id);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100/60 text-gray-900"
                                        : "text-gray-700",
                                      "group flex items-center px-4 py-2 text-sm hover:text-primaryDark w-full"
                                    )}
                                  >
                                    <PencilIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-primaryDark"
                                      aria-hidden="true"
                                    />
                                    Change Password
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                            <div className="py-1 w-full">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      deleteUser(v._id);
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100/60 text-gray-900"
                                        : "text-gray-700",
                                      "group flex items-center px-4 py-2 text-sm hover:text-primaryDark w-full"
                                    )}
                                  >
                                    <TrashIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-primaryDark"
                                      aria-hidden="true"
                                    />
                                    Delete
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <FloatNotification
        show={showFloatNotification.show}
        title={showFloatNotification.title}
        type={showFloatNotification.type}
        description={showFloatNotification.description}
        onClick={() => {
          setShowFloatNotification({ ...showFloatNotification, show: false });
        }}
      />
    </div>
  );
};

export default AdminHome;
