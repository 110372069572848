import * as React from "react";
import CurrentUser from "../utils/current-user";
import { getUserAPI } from "../api";

const AuthContext = React.createContext();
const initialValues = () => {
  return CurrentUser.get();
};
function AuthProvider(props) {
  const [user, setUser] = React.useState(initialValues);
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({
    _id: "",
    name: "",
    role: "",
    company_name: "",
    category: "",
    business_experience: "",
    dob: "",
    village: "",
    taluka: "",
    district: "",
    education: "",
    skill: [""],
    achievement: [""],
    interest: [""],
    service_details: [
      {
        department_name: "",
        department_services: [""],
      },
    ],
    ask: [""],
    give: [""],
    happy_client_list: [""],
    profile_url: [],
  });

  // clear the token in localStorage and the user data
  const logout = () => {
    CurrentUser.remove();
    window.location.reload();
  };

  const getUser = () => {
    try {
      if (user.accessToken) {
        setLoading(true);
        fetch(getUserAPI, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.user) {
              setUserData({
                _id: data.user._id,
                role: data.user.role,
                name: data.user.name ?? "",
                company_name: data.user.company_name ?? "",
                category: data.user.category ?? "",
                business_experience: data.user.business_experience ?? "",
                dob: data.user.dob ?? "",
                village: data.user.village ?? "",
                taluka: data.user.taluka ?? "",
                district: data.user.district ?? "",
                education: data.user.education ?? "",
                skill: data.user.skill.length ? data.user.skill : [""],
                achievement: data.user.achievement.length
                  ? data.user.achievement
                  : [""],
                interest: data.user.interest.length ? data.user.interest : [""],
                service_details:
                  data.user.service_details && data.user.service_details.length
                    ? data.user.service_details
                    : [
                        {
                          department_name: "",
                          department_services: [""],
                        },
                      ],
                service: data.user.service ?? "",
                ask: data.user.ask.length ? data.user.ask : [""],
                give: data.user.give.length ? data.user.give : [""],
                happy_client_list: data.user.happy_client_list.length
                  ? data.user.happy_client_list
                  : [""],
                profile_url: data.user.profile_url ?? "",
              });
              setLoading(false);
            }
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        CurrentUser,
        userData,
        setUserData,
        getUser,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
