import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Previews from "./Previews";
import { getUserAPI, getUserByIdAPI, updateuser } from "../api";
import { useAuth } from "../Component/AuthProvider";
import CircleLoader from "../Component/CircleLoader";

const Edit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading, setLoading } = useAuth();
  const [editData, setEditData] = useState({
    _id: "",
    name: "",
    company_name: "",
    category: "",
    business_experience: "",
    dob: "",
    village: "",
    taluka: "",
    district: "",
    education: "",
    skill: [""],
    achievement: [""],
    interest: [""],
    network: [""],
    service_details: [
      {
        department_name: "",
        department_services: [""],
      },
    ],
    ask: [""],
    give: [""],
    happy_client_list: [""],
    profile_url: "",
    services: [
      {
        department_name: "",
        department_services: [""],
      },
    ],
  });
  const userID = new URLSearchParams(location.search).get("id");

  const getUserById = () => {
    if (user.accessToken) {
      setLoading(true);
      fetch(getUserByIdAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ id: userID }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.user) {
            setEditData({
              _id: data.user._id,
              name: data.user.name ?? "",
              company_name: data.user.company_name ?? "",
              category: data.user.category ?? "",
              business_experience: data.user.business_experience ?? "",
              dob: data.user.dob ?? "",
              village: data.user.village ?? "",
              taluka: data.user.taluka ?? "",
              district: data.user.district ?? "",
              education: data.user.education ?? "",
              skill: data.user.skill.length ? data.user.skill : [""],
              achievement: data.user.achievement.length
                ? data.user.achievement
                : [""],
              interest: data.user.interest.length ? data.user.interest : [""],
              service_details: data.user.service_details.length
                ? data.user.service_details
                : [
                    {
                      department_name: "",
                      department_services: [""],
                    },
                  ],
              service: data.user.service ?? "",
              ask: data.user.ask.length ? data.user.ask : [""],
              give: data.user.give.length ? data.user.give : [""],
              happy_client_list: data.user.happy_client_list.length
                ? data.user.happy_client_list
                : [""],
              profile_url: data.user.profile_url ?? "",
            });
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userID) getUserById();
  }, []);

  const handleAchieveAdd = () => {
    setEditData({
      ...editData,
      achievement: [...editData.achievement, ""],
    });
  };

  const handleRemoveField = (field_name, index) => {
    let val = { ...editData };
    val[field_name].splice(index, 1);
    setEditData(val);
  };

  const handleAddField = (field_name) => {
    let val = { ...editData };
    val[field_name] = [...editData[field_name], ""];
    setEditData(val);
  };

  const handleClick = () => {
    fetch(updateuser, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...editData,
        skill: editData.skill[0].length ? editData.skill : [],
        achievement: editData.achievement[0].length ? editData.achievement : [],
        interest: editData.interest[0].length ? editData.interest : [],
        ask: editData.ask[0].length ? editData.ask : [],
        give: editData.give[0].length ? editData.give : [],
        service_details: editData.service_details[0].department_name.length
          ? editData.service_details
          : [],
        happy_client_list: editData.happy_client_list[0].length
          ? editData.happy_client_list
          : [],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "Success") {
          getUser();
          navigate(`/details?id=${userID}`);
        }
      });
  };

  const getUser = () => {
    if (user.accessToken) {
      setLoading(true);
      fetch(getUserAPI, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.user) {
            setEditData({
              _id: data.user._id,
              name: data.user.name ?? "",
              company_name: data.user.company_name ?? "",
              category: data.user.category ?? "",
              business_experience: data.user.business_experience ?? "",
              dob: data.user.dob ?? "",
              village: data.user.village ?? "",
              taluka: data.user.taluka ?? "",
              district: data.user.district ?? "",
              education: data.user.education ?? "",
              skill: data.user.skill.length ? data.user.skill : [""],
              achievement: data.user.achievement.length
                ? data.user.achievement
                : [""],
              interest: data.user.interest.length ? data.user.interest : [""],
              service_details: data.user.service_details ?? [
                {
                  department_name: "",
                  department_services: [""],
                },
              ],
              service: data.user.service ?? "",
              ask: data.user.ask.length ? data.user.ask : [""],
              give: data.user.give.length ? data.user.give : [""],
              happy_client_list: data.user.happy_client_list.length
                ? data.user.happy_client_list
                : [""],
              profile_url: data.user.profile_url ?? "",
            });
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <CircleLoader />
      ) : (
        <div>
          <div className="absolute right-5 top-5">
            <button onClick={handleClick}>
              <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
                Save
              </span>
            </button>
          </div>
          <h1 className="text-center text-5xl font-bold uppercase">
            V2V sheet
          </h1>
          <div className="max-w-screen-lg xl:mx-auto lg:mx-20 mx-5 my-16">
            <div className="lg:flex flex-row-reverse justify-between items-center space-y-5">
              <div className="space-y-2">
                <h3 className="text-xl text-center lg:text-left font-bold uppercase">
                  Professional Photo
                </h3>
                <Previews
                  files={editData.profile_url}
                  setFiles={(file) => {
                    setEditData({ ...editData, profile_url: file });
                  }}
                  className="w-96 h-96"
                />
              </div>
              <div className="lg:w-7/12 bg-gray-300 md:pl-4 py-8 space-y-5">
                <h3 className="text-2xl text-center lg:text-left font-bold px-2 uppercase">
                  Information:
                </h3>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Name:
                  </h2>
                  <input
                    id="input"
                    name="name"
                    onChange={(e) =>
                      setEditData({ ...editData, name: e.target.value })
                    }
                    value={editData.name}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Your Full Name"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Company Name:
                  </h2>
                  <input
                    id="input"
                    name="company_name"
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        company_name: e.target.value,
                      })
                    }
                    value={editData.company_name}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                    placeholder="Your Company Name"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Category:
                  </h2>
                  <input
                    id="input"
                    name="category"
                    onChange={(e) =>
                      setEditData({ ...editData, category: e.target.value })
                    }
                    value={editData.category}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Business Experience:
                  </h2>
                  <input
                    id="input"
                    name="business_experience"
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        business_experience: e.target.value,
                      })
                    }
                    value={editData.business_experience}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Birth Date:
                  </h2>
                  <input
                    id="input"
                    name="dob"
                    onChange={(e) =>
                      setEditData({ ...editData, dob: e.target.value })
                    }
                    value={editData.dob}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="date"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Village:
                  </h2>
                  <input
                    id="input"
                    name="village"
                    onChange={(e) =>
                      setEditData({ ...editData, village: e.target.value })
                    }
                    value={editData.village}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Taluka:
                  </h2>
                  <input
                    id="input"
                    name="taluka"
                    onChange={(e) =>
                      setEditData({ ...editData, taluka: e.target.value })
                    }
                    value={editData.taluka}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    District:
                  </h2>
                  <input
                    id="input"
                    name="district"
                    onChange={(e) =>
                      setEditData({ ...editData, district: e.target.value })
                    }
                    value={editData.district}
                    className="appearance-none w-full md:w-3/6  mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Education:
                  </h2>
                  <input
                    id="input"
                    name="education"
                    onChange={(e) =>
                      setEditData({ ...editData, education: e.target.value })
                    }
                    value={editData.education}
                    className="appearance-none w-full md:w-3/6 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Skill:</h3>
              </div>
              {editData.skill.map((e, i) => (
                <div key={i} className="my-3">
                  <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                    <input
                      name="skill"
                      type="text"
                      id="service"
                      placeholder="Add Items..."
                      className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      value={e}
                      onChange={(e) => {
                        const list = [...editData.skill];
                        list[i] = e.target.value;
                        setEditData({ ...editData, skill: list });
                      }}
                      required
                    />
                    {editData.skill.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveField("skill", i)}
                        className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {editData.skill.length < 10 && (
                <button type="button" onClick={() => handleAddField("skill")}>
                  <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                    Add
                  </span>
                </button>
              )}
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Achievement:</h3>
              </div>
              {editData.achievement.map((e, i) => (
                <div key={i} className="my-3">
                  <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                    <input
                      name="achievement"
                      type="text"
                      id="service"
                      placeholder="Add Items..."
                      className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      value={e}
                      onChange={(e) => {
                        const list = [...editData.achievement];
                        list[i] = e.target.value;
                        setEditData({ ...editData, achievement: list });
                      }}
                      required
                    />
                    {editData.achievement.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveField("achievement", i)}
                        className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {editData.achievement.length < 10 && (
                <button
                  type="button"
                  onClick={() => handleAchieveAdd("achievement")}
                >
                  <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                    Add
                  </span>
                </button>
              )}
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Interest:</h3>
              </div>
              {editData.interest.map((e, i) => (
                <div key={i} className="my-3 ">
                  <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                    <input
                      name="interest"
                      type="text"
                      id="service"
                      placeholder="Add Items..."
                      className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      value={e}
                      onChange={(e) => {
                        const list = [...editData.interest];
                        list[i] = e.target.value;
                        setEditData({ ...editData, interest: list });
                      }}
                      required
                    />
                    {editData.interest.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveField("interest", i)}
                        className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {editData.interest.length < 10 && (
                <button
                  type="button"
                  onClick={() => handleAddField("interest")}
                >
                  <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                    Add
                  </span>
                </button>
              )}
            </div>

            <div className="mt-16">
              <div className="flex justify-center mb-3">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">
                    Service Details:
                  </h3>
                </div>
              </div>
              <div>
                <div
                //  className="flex gap-10 flex-wrap"
                >
                  {editData.service_details.map((e, i) => (
                    <div className="mt-12" key={i}>
                      <div className="w-full sm:w-[50%] flex flex-col sm:flex-row sm:justify-between">
                        <div className="w-70 bg-gray-300 ">
                          <input
                            name="network"
                            type="text"
                            id="service"
                            placeholder="Enter service Name"
                            className="appearance-none w-full pl-6 py-3 mx-5 md:mx-2 block text-xl font-bold uppercase bg-gray-300 text-black rounded px-4 leading-tight focus:outline-none"
                            value={e.department_name}
                            onChange={(e) => {
                              const list = [...editData.service_details];
                              list[i].department_name = e.target.value;
                              setEditData({
                                ...editData,
                                service_details: list,
                              });
                            }}
                            required
                          />
                        </div>
                        <div className="flex flex-row justify-between mt-5 sm:mt-0">
                          {editData.service_details[i].department_services
                            .length < 10 && (
                            <button
                              type="button"
                              onClick={() => {
                                let list = [...editData.service_details];
                                let data = [...list[i].department_services];
                                data = [...data, ""];
                                list[i].department_services = data;
                                setEditData({
                                  ...editData,
                                  service_details: list,
                                });
                              }}
                            >
                              <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                                Add
                              </span>
                            </button>
                          )}
                          {editData.service_details.length !== 1 && (
                            <button
                              type="button"
                              onClick={() => {
                                const list = [...editData.service_details];
                                list.splice(i, 1);
                                setEditData({
                                  ...editData,
                                  service_details: list,
                                });
                              }}
                              className="remove-btn  text-[#c51a1A] mx-5"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      {e.department_services.map((service, ind) => (
                        <div key={ind} className="my-3 ">
                          <div className="md:w-3/6  py-3 bg-gray-100 flex items-center justify-between my-5">
                            <input
                              name="network"
                              type="text"
                              id="service"
                              placeholder="Add Items..."
                              className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                              value={service}
                              onChange={(e) => {
                                let list = [...editData.service_details];
                                let data = [...list[i].department_services];
                                data[ind] = e.target.value;
                                list[i].department_services = data;
                                setEditData({
                                  ...editData,
                                  service_details: list,
                                });
                              }}
                              required
                            />
                            {e.department_services.length !== 1 && (
                              <button
                                type="button"
                                onClick={() => {
                                  let list = [...editData.service_details];
                                  let data = [...list[i].department_services];
                                  data.splice(ind, 1);
                                  list[i].department_services = data;
                                  setEditData({
                                    ...editData,
                                    service_details: list,
                                  });
                                }}
                                className="remove-btn  text-[#c51a1A] mr-5"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              {editData.service_details.length < 10 && (
                <button
                  type="button"
                  onClick={() => {
                    setEditData({
                      ...editData,
                      service_details: [
                        ...editData.service_details,
                        {
                          department_name: "",
                          department_services: [""],
                        },
                      ],
                    });
                  }}
                >
                  <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                    Add Service
                  </span>
                </button>
              )}
            </div>

            <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 lg:divide lg:divide-x-2 lg:divide-black ">
              <div>
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">Ask and Give:</h3>
                </div>
                <h4 className="text-lg font-bold mt-5">Ask:</h4>
                {editData.ask.map((e, i) => (
                  <div key={i} className="my-3 ">
                    <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                      <input
                        name="ask"
                        type="text"
                        id="service"
                        placeholder="Add Items..."
                        className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        value={e}
                        onChange={(e) => {
                          const list = [...editData.ask];
                          list[i] = e.target.value;
                          setEditData({ ...editData, ask: list });
                        }}
                        required
                      />
                      {editData.ask.length !== 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveField("ask", i)}
                          className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      )}
                    </div>
                    {editData.ask.length - 1 === i &&
                      editData.ask.length < 10 && (
                        <button
                          type="button"
                          onClick={() => handleAddField("ask")}
                        >
                          <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                            Add
                          </span>
                        </button>
                      )}
                  </div>
                ))}
                <h4 className="text-lg font-bold mt-10">give:</h4>
                {editData.give.map((e, i) => (
                  <div key={i} className="my-3 ">
                    <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                      <input
                        name="give"
                        type="text"
                        id="service"
                        placeholder="Add Items..."
                        className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        value={e}
                        onChange={(e) => {
                          const list = [...editData.give];
                          list[i] = e.target.value;
                          setEditData({ ...editData, give: list });
                        }}
                        required
                      />
                      {editData.give.length !== 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveField("give", i)}
                          className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      )}
                    </div>
                    {editData.give.length - 1 === i &&
                      editData.give.length < 10 && (
                        <button
                          type="button"
                          onClick={() => handleAddField("give")}
                        >
                          <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                            Add
                          </span>
                        </button>
                      )}
                  </div>
                ))}
              </div>
              <div className="lg:pl-28">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">
                    Happy client list:
                  </h3>
                </div>
                {editData.happy_client_list.map((e, i) => (
                  <div key={i} className="my-3 ">
                    <div className="lg:w-7/12 py-3 bg-gray-100 flex items-center justify-between my-5">
                      <input
                        name="client"
                        type="text"
                        id="service"
                        placeholder="Add Items..."
                        className="appearance-none w-full mx-5 md:mx-2 block bg-transparent text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        value={e}
                        onChange={(e) => {
                          const list = [...editData.happy_client_list];
                          list[i] = e.target.value;
                          setEditData({
                            ...editData,
                            happy_client_list: list,
                          });
                        }}
                        required
                      />
                      {editData.happy_client_list.length !== 1 && (
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveField("happy_client_list", i)
                          }
                          className="remove-btn bg-gray-100 text-[#c51a1A] pr-5"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      )}
                    </div>
                    {editData.happy_client_list.length - 1 === i &&
                      editData.happy_client_list.length < 10 && (
                        <button
                          type="button"
                          onClick={() => handleAddField("happy_client_list")}
                        >
                          <span className="py-2 px-10 rounded-md bg-[#034766] text-lg font-semibold text-white">
                            Add
                          </span>
                        </button>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center mb-5">
            <button onClick={handleClick}>
              <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
                Save
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Edit;
