import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Details from "./Pages/Details";
import Edit from "./Pages/Edit";
import SignIn from "./Component/SignIn";
import AuthWrapper from "./Component/Auth";
import { AuthProvider } from "./Component/AuthProvider";
import SignUp from "./Component/SignUp";

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<SignIn />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route
              path="/"
              element={
                <AuthWrapper>
                  <Home />
                </AuthWrapper>
              }
            ></Route>
            <Route
              path="/details"
              element={
                <AuthWrapper>
                  <Details />
                </AuthWrapper>
              }
            ></Route>
            <Route
              path="/edit"
              element={
                <AuthWrapper>
                  <Edit />
                </AuthWrapper>
              }
            ></Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
