import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import { changePasswordAPI } from "../api";
import { useAuth } from "../Component/AuthProvider";
import { useFormik } from "formik";
import { TeaxtField } from "../Component/TeaxtField";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const { user, userData } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    setFieldError,
    handleSubmit,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      currentpassword: "",
      newpassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      currentpassword: Yup.string()
        .min(6, "current password is invalid")
        .required("current password is required"),
      newpassword: Yup.string()
        .min(6, "Password must be at least 6 charaters")
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newpassword"), null], "Password must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      changePassword();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValues({
      newpassword: "",
      confirmPassword: "",
      currentpassword: "",
    });
  };

  const changePassword = () => {
    if (user.accessToken) {
      try {
        fetch(changePasswordAPI, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentPassword: values.currentpassword,
            newPassword: values.newpassword,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "failed") {
              setFieldError("currentpassword", data.message);
            } else {
              setOpen(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <div className="fixed bottom-8 left-5">
        <div
          className="circle flex justify-center items-center p-4 text-4xl text-white"
          onClick={handleClickOpen}
        >
          <i className="fa-sharp fa-solid fa-gear"></i>
        </div>
      </div>
      <Dialog open={open}>
        <h1 className="text-center text-2xl font-bold mt-8">Password</h1>
        <DialogContent>
          <p className="text-base px-8">
            Your password must be at least 6 characters and shoud include a
            combination of number,letters and special characters(!$@%).
          </p>
          <label className="text-sm font-medium text-gray-900 dark:text-white">
            Current Password:
          </label>
          <TeaxtField
            id="password"
            name="password"
            type="password"
            placeholder="Enter Current Password"
            value={values.currentpassword}
            onChange={(e) => setFieldValue("currentpassword", e.target.value)}
            error={
              errors.currentpassword && touched.currentpassword ? true : false
            }
            errorText={errors.currentpassword}
          />
          <label className="text-sm font-medium text-gray-900 dark:text-white">
            New Password:
          </label>
          <TeaxtField
            id="password"
            name="password"
            type="password"
            placeholder="Enter New Password"
            value={values.newpassword}
            onChange={(e) => setFieldValue("newpassword", e.target.value)}
            error={errors.newpassword && touched.newpassword ? true : false}
            errorText={errors.newpassword}
          />
          <label className="text-sm font-medium text-gray-900 dark:text-white">
            Confirm Password:
          </label>
          <TeaxtField
            id="password"
            name="password"
            type="password"
            placeholder="Enter New Confirm Password"
            value={values.confirmPassword}
            onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
            error={
              errors.confirmPassword && touched.confirmPassword ? true : false
            }
            errorText={errors.confirmPassword}
          />
          <div className="flex flex-row justify-end my-5">
            <button
              onClick={() => handleClose()}
              className="text-white mx-5 bg-gray-600 hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Cancel
            </button>
            <button
              onClick={() => handleSubmit()}
              className="text-white bg-gray-600 hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Change Password
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Setting;
