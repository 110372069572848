import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import { getUserByIdAPI } from "../api";
import CircleLoader from "../Component/CircleLoader";
import { useAuth } from "../Component/AuthProvider";

const Details = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const userID = new URLSearchParams(location.search).get("id");
  const [state, setState] = useState({
    _id: "",
    name: "",
    company_name: "",
    category: "",
    business_experience: "",
    dob: "",
    village: "",
    taluka: "",
    district: "",
    education: "",
    skill: [""],
    achievement: [""],
    interest: [""],
    network: [""],
    service_details: [
      {
        department_name: "",
        department_services: [""],
      },
    ],
    ask: [""],
    give: [""],
    happy_client_list: [""],
    profile_url: "",
  });
  const componentRef = useRef();
  const [baseImage, setBaseImage] = useState("");

  const uploadImage = async (image) => {
    const file = await getBlob(image);
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const getBlob = async (fileUri) => {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();
    return imageBody;
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getUserById = () => {
    if (user.accessToken) {
      setLoading(true);
      fetch(getUserByIdAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({ id: userID }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.user) {
            setState({
              _id: data.user._id,
              name: data.user.name ?? "",
              company_name: data.user.company_name ?? "",
              category: data.user.category ?? "",
              business_experience: data.user.business_experience ?? "",
              dob: data.user.dob ?? "",
              village: data.user.village ?? "",
              taluka: data.user.taluka ?? "",
              district: data.user.district ?? "",
              education: data.user.education ?? "",
              skill: data.user.skill.length ? data.user.skill : [""],
              achievement: data.user.achievement.length
                ? data.user.achievement
                : [""],
              interest: data.user.interest.length ? data.user.interest : [""],
              service_details: data.user.service_details.length
                ? data.user.service_details
                : [
                    {
                      department_name: "",
                      department_services: [""],
                    },
                  ],
              service: data.user.service ?? "",
              ask: data.user.ask.length ? data.user.ask : [""],
              give: data.user.give.length ? data.user.give : [""],
              happy_client_list: data.user.happy_client_list.length
                ? data.user.happy_client_list
                : [""],
              profile_url: data.user.profile_url ?? "",
            });
            uploadImage(data.user.profile_url);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userID) getUserById();
  }, []);

  const onFormSubmit = () => {
    navigate(`/edit?id=${state._id}`);
  };

  const downloadClick = () => {
    if (componentRef.current) {
      componentRef.current.save();
    }
  };

  return (
    <>
      {userData._id === userID && (
        <div className="absolute top-5 right-0">
          <button onClick={onFormSubmit}>
            <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
              Edit
            </span>
          </button>
          <button onClick={downloadClick}>
            <span className="mx-2 bg-red-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
              Print
            </span>
          </button>
          <button onClick={downloadClick}>
            <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
              Share
            </span>
          </button>
        </div>
      )}
      <PDFExport
        ref={componentRef}
        scale={0.55}
        paperSize={"A4"}
        margin={"0.25in"}
      >
        <h1 className="text-center text-5xl font-bold uppercase">V2V sheet</h1>
        {loading ? (
          <CircleLoader />
        ) : (
          <div className="max-w-screen-lg xl:mx-auto lg:mx-20 mx-5 my-16 p-3">
            <div className="lg:flex flex-row-reverse justify-between items-center space-y-5">
              <div className="space-y-2">
                <h3 className="text-xl text-center lg:text-left font-bold uppercase">
                  Professional Photo
                </h3>
                {baseImage && (
                  <img
                    id="uploadedimage"
                    src={baseImage}
                    className="w-56 h-64 border-black border-2 mx-auto lg:mx-0"
                    alt="people"
                  ></img>
                )}
              </div>
              <div className="lg:w-7/12 bg-gray-300 md:pl-4 py-8 space-y-5">
                <h3 className="text-2xl text-center lg:text-left font-bold px-2 uppercase">
                  Information:
                </h3>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Name:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.name}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Company Name:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.company_name}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Category:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.category}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Business Experience:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.business_experience}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Birth Date:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.dob}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Village:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.village}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Taluka:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.taluka}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    District:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.district}
                  </span>
                </div>
                <div className="md:flex flex-wrap items-center">
                  <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                    Education:
                  </h2>
                  <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                    {state.education}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Skill:</h3>
              </div>
              {state.skill.map((e, i) => (
                <div key={i} className="my-3 flex items-center">
                  <div className="w-2 h-2 bg-black rounded-full"></div>
                  <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                    {e}
                  </span>
                </div>
              ))}
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Achievement:</h3>
              </div>
              {state.achievement.map((e, i) => (
                <div key={i} className="my-3 flex items-center">
                  <div className="w-2 h-2 bg-black rounded-full"></div>
                  <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                    {e}
                  </span>
                </div>
              ))}
            </div>

            <div className="mt-16">
              <div className="w-64 bg-gray-300 pl-6 py-3">
                <h3 className="text-xl font-bold uppercase">Interest:</h3>
              </div>
              {state.interest.map((e, i) => (
                <div key={i} className="my-3 flex items-center">
                  <div className="w-2 h-2 bg-black rounded-full"></div>
                  <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                    {e}
                  </span>
                </div>
              ))}
            </div>

            <div className="mt-16">
              <div className="flex justify-center mb-5">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">
                    Service Details:
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2">
                {state.service_details.map((e, i) => (
                  <div className="mb-10 w-[70%]">
                    <div className="w-full bg-gray-300 pl-6 py-3">
                      <h3 className="text-xl font-bold uppercase">
                        {e.department_name}:
                      </h3>
                    </div>
                    {e.department_services.map((service, ind) => (
                      <div key={ind} className="my-3 flex items-center">
                        <div className="w-2 h-2 bg-black rounded-full"></div>
                        <span className=" mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                          {service}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 lg:divide lg:divide-x-2 lg:divide-black ">
              <div>
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">Ask and Give:</h3>
                </div>
                <h4 className="text-lg font-bold mt-5">Ask:</h4>
                {state.ask.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
                <h4 className="text-lg font-bold mt-10">give:</h4>
                {state.give.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
              </div>
              <div className="lg:pl-28">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">
                    Happy client list:
                  </h3>
                </div>
                {state.happy_client_list.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </PDFExport>
    </>
  );
};

export default Details;
