import React from "react";

export const TeaxtField = ({ label, error, errorText, name, ...props }) => {
  return (
    <div className=" py-1 ">
      <label htmlFor={name}>{label}</label>
      <input
        {...props}
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
        autoComplete="off"
      />
      {error && <div className="text-red-500 text-sm"> {errorText}</div>}
    </div>
  );
};
