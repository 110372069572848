import Setting from "./Setting";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Component/AuthProvider";
import CircleLoader from "../Component/CircleLoader";
import { PDFExport } from "@progress/kendo-react-pdf";
import AdminHome from "./AdminHome";

const getBlob = async (fileUri) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

const Home = () => {
  const componentRef = useRef();
  const { userData, logout, loading } = useAuth();
  const navigate = useNavigate();
  const [baseImage, setBaseImage] = useState("");
  const [state, setState] = useState({
    _id: "",
    name: "",
    company_name: "",
    category: "",
    business_experience: "",
    dob: "",
    village: "",
    taluka: "",
    district: "",
    education: "",
    skill: [""],
    achievement: [""],
    interest: [""],
    network: [""],
    service_details: [
      {
        department_name: "",
        department_services: [""],
      },
    ],
    ask: [""],
    give: [""],
    happy_client_list: [""],
    profile_url: "",
  });

  const uploadImage = async (image) => {
    const file = await getBlob(image);
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const downloadClick = () => {
    if (componentRef.current) {
      componentRef.current.save();
    }
  };

  const shareClick = () => {
    window.open(
      `https://web.whatsapp.com/send?text=http:192.168.29.51:3500/details?id=${state._id}`
    );
  };

  useEffect(() => {
    setState(userData);
    uploadImage(userData.profile_url);
  }, [userData]);
  console.log("userData.roleuserData.role", userData.role);
  return (
    <>
      {loading ? (
        <CircleLoader />
      ) : userData.role === "99" ? (
        <AdminHome />
      ) : (
        <>
          <div className="absolute right-5 top-5">
            <button
              onClick={() => logout()}
              className="text-white bg-gray-600 hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Logout
            </button>
          </div>
          <div className="flex justify-center mt-22">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-96"
              width="654.05"
              height="157.937"
              viewBox="0 0 654.05 157.937"
            >
              <g
                id="Group_34619"
                data-name="Group 34619"
                transform="translate(-4347.815 2283)"
              >
                <path
                  id="Path_44071"
                  data-name="Path 44071"
                  d="M177.212,45.813l.051,12.569c-4.652,1.046-12.756.465-17.772.46-6.436-.005-12.585.717-12.6-6.289-.035-6.971,5.076-7.158,11.659-7.2,5.112-.03,14.056-.571,18.667.46m-43.75-25.2c9.333.04,18.621-.071,27.9-.005,10.547.066,15.583,1.051,15.659,11.781-11.422.616-25.639-1.258-35.852,1.879-9.783,3-14.839,14.034-11.234,25.88,3.236,10.644,13.065,11.786,25.9,11.76,12.772-.02,25.781.263,38.512-.081.389-10.225.106-20.894.106-31.169.01-10.775.849-19.025-5.273-26.011-10.158-11.594-37.779-7.572-55.419-7.6Z"
                  transform="translate(4734.316 -2257.085)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44072"
                  data-name="Path 44072"
                  d="M65.286,20.555c27.879.328,43.406-4.471,43.664,11.973-10.739.035-26.6-1.02-35.857,1.8-9.839,3-14.789,13.534-11.118,25.759C65.241,71,74.857,71.9,87.679,71.89c12.8-.015,25.877.268,38.648-.086.551-10.043.131-21.015.137-31.164.01-10.28.829-19.51-5.354-26.1C110.447,3.167,83.453,7.047,65.59,7.012Zm43.866,25.122.1,12.8c-4.97.834-12.726.354-17.919.354-5.7,0-12.655.96-12.686-6.289-.02-6.613,5.658-7.158,11.735-7.2,5.294-.03,13.874-.455,18.773.338"
                  transform="translate(4458.459 -2257.065)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44073"
                  data-name="Path 44073"
                  d="M147.672,9.077c.657,4,1.871,2.753,2.882,6.906,1.052,4.344-.46,4.87,2.518,8.593,3.352,4.193,6.472,6.512,6.719,14.65.875,28.653-41.237,29.436-42.218,1.809-.3-8.34,2.23-10.508,5.87-14.948,2.584-3.147,2.356-3.865,2.988-7.835.774-4.85,1.942-4.749,2.8-8.26C115.894-4.725,97.789,26.116,100.388,45.247c2.488,18.353,19.3,34.074,39.877,33.543,41.444-1.071,50.576-55.326,20.745-72.163-6.315-3.567-9.637-.864-13.338,2.45"
                  transform="translate(4618.157 -2262.984)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44074"
                  data-name="Path 44074"
                  d="M60.532,75.531c-1.117-3.051-2.144-3.607-2.821-7.421C55,52.834,48.59,59.618,48.757,45.388c.329-28.542,43.29-27.35,42.44.808-.228,7.426-3.5,10.583-6.578,14.2-2.8,3.289-1.886,4.213-2.67,8.244-.93,4.789-2.346,4.117-3.125,7.81,8.671,8.871,17.428.945,22.161-5.2,21.306-27.7,1.906-62.045-27.758-64.318C51.911,5.292,34.979,21.4,31.859,38.785c-3.7,20.576,15.193,51.669,28.673,36.746"
                  transform="translate(4339.315 -2255.391)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44075"
                  data-name="Path 44075"
                  d="M90.526,73.086l33.456.04c5.86-10.012,11.978-21.96,17.539-32.866,2.968-5.815,5.754-10.871,8.823-16.716,2.624-5,6.866-11.786,8.787-17.044l-23.293-.025c-2.2,5.375-21.625,41.192-24.163,43.7-1.567-9.088-2.442-38.4-4.8-43.505L86.259,6.459Z"
                  transform="translate(4561.87 -2256.831)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44076"
                  data-name="Path 44076"
                  d="M174.405,73.427l.248-14.074c-20.639-1.056-40.388,5.112-40.322-19.474.061-24.263,20.993-17.63,40.357-19.1V7.008c-26.089-1.5-58.22-3.45-58.438,32.866-.086,14.135,6.558,24.824,15.659,29.83,9.708,5.34,29.856,4.3,42.5,3.723"
                  transform="translate(4683.514 -2257.218)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44077"
                  data-name="Path 44077"
                  d="M106.033,73.362l.1-14.2c-18.95-.935-39.872,5.3-40.236-18.59C65.513,16.238,85.3,21.411,105.744,20.84l.5-13.978C93.767,6.357,73.386,5.387,63.83,10.014c-20.917,10.124-21.589,48.072-.4,59.691,9.515,5.218,30.215,4.248,42.607,3.657"
                  transform="translate(4406.028 -2257.001)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44078"
                  data-name="Path 44078"
                  d="M93.334,104.064l12.888-7.714L99.447,3.05l-6.113,5.5L86.61,2.575,80.451,96.526Z"
                  transform="translate(4538.313 -2272.567)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44079"
                  data-name="Path 44079"
                  d="M143.443,104.541l17.271-.01.359-102.166L143.509,2.25Z"
                  transform="translate(4793.812 -2273.886)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44080"
                  data-name="Path 44080"
                  d="M26.862,104.373l17.691.212c1.224-5.1,1.016-98.725-.157-102.327L27.3,2.283Z"
                  transform="translate(4320.953 -2273.852)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44081"
                  data-name="Path 44081"
                  d="M75.733,73.1l7.109.2,10.39-.167,5.7-66.7-24.122.354Z"
                  transform="translate(4515.445 -2256.949)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <rect
                  id="Rectangle_23435"
                  data-name="Rectangle 23435"
                  width="6.137"
                  height="6.137"
                  transform="matrix(0.708, -0.707, 0.707, 0.708, 4627.311, -2274.331)"
                  fill="#034766"
                />
                <path
                  id="Path_44082"
                  data-name="Path 44082"
                  d="M96.485,8.674,89.963,15.19,83.441,8.674l6.522-6.517ZM89.963,0,81.282,8.674l8.681,8.669,8.681-8.669Z"
                  transform="translate(4541.683 -2283)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44083"
                  data-name="Path 44083"
                  d="M39,20.634H53.786a1.864,1.864,0,1,1,0,3.728L39,24.357A1.858,1.858,0,0,1,37.136,22.5v-.005A1.861,1.861,0,0,1,39,20.634"
                  transform="translate(4362.625 -2199.398)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44084"
                  data-name="Path 44084"
                  d="M39,21.449H53.786a1.864,1.864,0,1,1,0,3.728L39,25.172a1.858,1.858,0,0,1-1.861-1.859v-.005A1.861,1.861,0,0,1,39,21.449"
                  transform="translate(4362.625 -2196.096)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44085"
                  data-name="Path 44085"
                  d="M39.457,22.264H49.584a1.869,1.869,0,0,1,1.866,1.859v.005a1.869,1.869,0,0,1-1.866,1.859H39.457A1.868,1.868,0,0,1,37.6,24.128v-.005a1.861,1.861,0,0,1,1.861-1.859"
                  transform="translate(4364.491 -2192.793)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44086"
                  data-name="Path 44086"
                  d="M107.656,8.745h14.789a1.862,1.862,0,1,0,0-3.723H107.656a1.862,1.862,0,0,0,0,3.723"
                  transform="translate(4641.109 -2262.653)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44087"
                  data-name="Path 44087"
                  d="M107.656,7.934h14.789a1.864,1.864,0,1,0,0-3.728l-14.789.005a1.861,1.861,0,0,0-1.861,1.859v.005a1.865,1.865,0,0,0,1.861,1.859"
                  transform="translate(4641.109 -2265.959)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <path
                  id="Path_44088"
                  data-name="Path 44088"
                  d="M108.117,7.119h10.127a1.864,1.864,0,1,0,0-3.728L108.117,3.4a1.865,1.865,0,0,0-1.861,1.859V5.26a1.865,1.865,0,0,0,1.861,1.859"
                  transform="translate(4642.979 -2269.261)"
                  fill="#034766"
                  fillRule="evenodd"
                />
                <text
                  id="B"
                  transform="translate(4483.75 -2135.064)"
                  fill="#034766"
                  fontSize="49"
                  fontFamily="Arial-BoldMT, Arial"
                  fontWeight="700"
                >
                  <tspan x="0" y="0">
                    B
                  </tspan>
                </text>
                <g
                  id="Group_34618"
                  data-name="Group 34618"
                  transform="translate(4212 -2283)"
                >
                  <path
                    id="Path_44089"
                    data-name="Path 44089"
                    d="M62.661,21.831h7.422V41.669a42.318,42.318,0,0,0,.268,6.118,5.971,5.971,0,0,0,2.24,3.617,7.759,7.759,0,0,0,4.844,1.359,7.292,7.292,0,0,0,4.7-1.288,5.058,5.058,0,0,0,1.9-3.157,41.885,41.885,0,0,0,.324-6.224V21.831h7.422V41.083a49.55,49.55,0,0,1-.6,9.32,10.383,10.383,0,0,1-2.21,4.6,10.994,10.994,0,0,1-4.293,2.981A18.62,18.62,0,0,1,77.652,59.1,20.131,20.131,0,0,1,69.724,57.9a11.354,11.354,0,0,1-4.272-3.152,10.07,10.07,0,0,1-2.068-4.067,44.363,44.363,0,0,1-.723-9.3Z"
                    transform="translate(254.156 88.452)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44090"
                    data-name="Path 44090"
                    d="M71.069,47.109l7.215-.717A8.77,8.77,0,0,0,80.913,51.7a7.92,7.92,0,0,0,5.349,1.7,8.2,8.2,0,0,0,5.364-1.51,4.5,4.5,0,0,0,1.805-3.526,3.363,3.363,0,0,0-.758-2.218,6.041,6.041,0,0,0-2.654-1.576c-.86-.308-2.821-.834-5.89-1.6q-5.923-1.485-8.317-3.637A9.707,9.707,0,0,1,74.027,26.69a9.978,9.978,0,0,1,4.566-3.718,18.229,18.229,0,0,1,7.19-1.273q6.871,0,10.345,3.046a10.825,10.825,0,0,1,3.65,8.148l-7.417.258a6.35,6.35,0,0,0-2.022-4.077,7.364,7.364,0,0,0-4.652-1.243,8.32,8.32,0,0,0-5.005,1.329,2.7,2.7,0,0,0-1.168,2.283,2.812,2.812,0,0,0,1.1,2.228q1.4,1.182,6.78,2.46a35.712,35.712,0,0,1,7.958,2.632,10.274,10.274,0,0,1,4.04,3.723,11.53,11.53,0,0,1-.288,11.73A10.5,10.5,0,0,1,94.16,58.3a20.722,20.722,0,0,1-7.978,1.339q-6.939,0-10.668-3.208t-4.444-9.32"
                    transform="translate(288.259 87.917)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44091"
                    data-name="Path 44091"
                    d="M93.159,58.44V21.83h27.126v6.188h-19.7v8.128H118.9v6.188H100.581v9.917H121V58.44Z"
                    transform="translate(377.857 88.449)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44092"
                    data-name="Path 44092"
                    d="M100.64,47.109l7.215-.717a8.743,8.743,0,0,0,2.629,5.309,7.915,7.915,0,0,0,5.344,1.7,8.184,8.184,0,0,0,5.364-1.51A4.5,4.5,0,0,0,123,48.362a3.39,3.39,0,0,0-.758-2.218,6.071,6.071,0,0,0-2.654-1.576c-.86-.308-2.821-.834-5.89-1.6q-5.923-1.485-8.317-3.637A9.691,9.691,0,0,1,103.6,26.69a9.978,9.978,0,0,1,4.566-3.718,18.229,18.229,0,0,1,7.19-1.273q6.871,0,10.345,3.046a10.825,10.825,0,0,1,3.65,8.148l-7.417.258a6.35,6.35,0,0,0-2.022-4.077,7.364,7.364,0,0,0-4.652-1.243,8.31,8.31,0,0,0-5.006,1.329,2.7,2.7,0,0,0-1.168,2.283,2.822,2.822,0,0,0,1.1,2.228q1.4,1.182,6.785,2.46a35.712,35.712,0,0,1,7.958,2.632,10.308,10.308,0,0,1,4.04,3.723,11.53,11.53,0,0,1-.288,11.73,10.493,10.493,0,0,1-4.95,4.082,20.7,20.7,0,0,1-7.973,1.339q-6.947,0-10.668-3.208t-4.444-9.32"
                    transform="translate(408.2 87.917)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44093"
                    data-name="Path 44093"
                    d="M109.315,47.109l7.215-.717a8.743,8.743,0,0,0,2.629,5.309,7.915,7.915,0,0,0,5.344,1.7,8.184,8.184,0,0,0,5.364-1.51,4.5,4.5,0,0,0,1.81-3.526,3.39,3.39,0,0,0-.758-2.218,6.071,6.071,0,0,0-2.654-1.576c-.86-.308-2.821-.834-5.89-1.6q-5.923-1.485-8.317-3.637a9.691,9.691,0,0,1-1.785-12.639,9.978,9.978,0,0,1,4.566-3.718,18.229,18.229,0,0,1,7.19-1.273q6.871,0,10.345,3.046a10.824,10.824,0,0,1,3.65,8.148l-7.417.258a6.351,6.351,0,0,0-2.022-4.077,7.365,7.365,0,0,0-4.652-1.243,8.32,8.32,0,0,0-5.005,1.329,2.7,2.7,0,0,0-1.168,2.283,2.812,2.812,0,0,0,1.1,2.228q1.4,1.182,6.78,2.46a35.712,35.712,0,0,1,7.958,2.632,10.308,10.308,0,0,1,4.04,3.723,11.53,11.53,0,0,1-.288,11.73,10.5,10.5,0,0,1-4.945,4.082,20.74,20.74,0,0,1-7.978,1.339q-6.947,0-10.668-3.208t-4.444-9.32"
                    transform="translate(443.386 87.917)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44094"
                    data-name="Path 44094"
                    d="M88.229,26.195l-7.71-4.405h-.379V58.606h8.09Z"
                    transform="translate(325.047 88.284)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_44095"
                    data-name="Path 44095"
                    d="M84.282,26.4v32.21h7.933l-.308-27.789,15.78,27.789h8.888V21.789H108.8l.324,26.532L94.1,21.789H92.124Z"
                    transform="translate(341.85 88.283)"
                    fill="#034766"
                    fillRule="evenodd"
                  />
                  <text
                    id="_"
                    data-name="®"
                    transform="translate(760.865 40.718)"
                    fill="#034766"
                    fontSize="39"
                    fontFamily="ArialMT, Arial"
                  >
                    <tspan x="0" y="0">
                      ®
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div className="flex justify-center mt-5 mb-8">
            <button onClick={() => navigate(`/edit?id=${state._id}`)}>
              <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
                Edit
              </span>
            </button>
            <button onClick={downloadClick}>
              <span className="mx-2 bg-red-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
                Print
              </span>
            </button>
            <button onClick={shareClick}>
              <span className="mx-2 bg-green-700 text-white font-medium px-6 py-2 rounded-md tracking-wide uppercase">
                Share
              </span>
            </button>
          </div>
          <PDFExport
            ref={componentRef}
            scale={0.55}
            paperSize={"A4"}
            margin={"0.25in"}
          >
            <div className="max-w-screen-lg xl:mx-auto lg:mx-20 mx-5 p-3">
              <div className="lg:flex flex-row-reverse justify-between items-center space-y-5">
                <div className="space-y-2">
                  <h3 className="text-xl text-center lg:text-left font-bold uppercase">
                    Professional Photo
                  </h3>
                  {baseImage && (
                    <img
                      id="profileImage"
                      src={baseImage}
                      className="w-56 h-64 border-black border-2 mx-auto lg:mx-0"
                      alt="people"
                    ></img>
                  )}
                </div>
                <div className="lg:w-7/12 bg-gray-300 md:pl-4 py-8 space-y-5">
                  <h3 className="text-2xl text-center lg:text-left font-bold px-2 uppercase">
                    Information:
                  </h3>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Name:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.name}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Company Name:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.company_name}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Category:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.category}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Business Experience:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.business_experience}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Birth Date:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.dob}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Village:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.village}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Taluka:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.taluka}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      District:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.district}
                    </span>
                  </div>
                  <div className="md:flex flex-wrap items-center">
                    <h2 className="tracking-wide text-center text-gray-700 text-xl px-2 font-medium">
                      Education:
                    </h2>
                    <span className="md:w-6/12 mx-5 md:mx-0 block bg-gray-200 text-gray-700 rounded py-2 pl-3 leading-tight">
                      {state.education}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-16">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">Skill:</h3>
                </div>
                {state.skill.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
              </div>

              <div className="mt-16">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">Achievement:</h3>
                </div>
                {state.achievement.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
              </div>

              <div className="mt-16">
                <div className="w-64 bg-gray-300 pl-6 py-3">
                  <h3 className="text-xl font-bold uppercase">Interest:</h3>
                </div>
                {state.interest.map((e, i) => (
                  <div key={i} className="my-3 flex items-center">
                    <div className="w-2 h-2 bg-black rounded-full"></div>
                    <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                      {e}
                    </span>
                  </div>
                ))}
              </div>

              <div className="mt-16">
                <div className="flex justify-center mb-5">
                  <div className="w-64 bg-gray-300 pl-6 py-3">
                    <h3 className="text-xl font-bold uppercase">
                      Service Details:
                    </h3>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {state.service_details.map((e, i) => (
                    <div className="mb-10 w-[70%]">
                      <div className="w-full bg-gray-300 pl-6 py-3">
                        <h3 className="text-xl font-bold uppercase">
                          {e.department_name}:
                        </h3>
                      </div>
                      {e.department_services.map((service, ind) => (
                        <div key={ind} className="my-3 flex items-center">
                          <div className="w-2 h-2 bg-black rounded-full"></div>
                          <span className=" mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                            {service}
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 lg:divide lg:divide-x-2 lg:divide-black ">
                <div>
                  <div className="w-64 bg-gray-300 pl-6 py-3">
                    <h3 className="text-xl font-bold uppercase">
                      Ask and Give:
                    </h3>
                  </div>
                  <h4 className="text-lg font-bold mt-5">Ask:</h4>
                  {state.ask.map((e, i) => (
                    <div key={i} className="my-3 flex items-center">
                      <div className="w-2 h-2 bg-black rounded-full"></div>
                      <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                        {e}
                      </span>
                    </div>
                  ))}
                  <h4 className="text-lg font-bold mt-10">give:</h4>
                  {state.give.map((e, i) => (
                    <div key={i} className="my-3 flex items-center">
                      <div className="w-2 h-2 bg-black rounded-full"></div>
                      <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                        {e}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="lg:pl-28">
                  <div className="w-64 bg-gray-300 pl-6 py-3">
                    <h3 className="text-xl font-bold uppercase">
                      Happy client list:
                    </h3>
                  </div>
                  {state.happy_client_list.map((e, i) => (
                    <div key={i} className="my-3 flex items-center">
                      <div className="w-2 h-2 bg-black rounded-full"></div>
                      <span className="md:w-6/12 mx-5 md:mx-0 block text-gray-700 rounded pl-3 leading-tight">
                        {e}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </PDFExport>
          <Setting />
        </>
      )}
    </>
  );
};
export default Home;
