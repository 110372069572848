import React from "react";
import CurrentUser from "../utils/current-user";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

export default function AuthWrapper(props) {
  const { user } = useAuth();
  if (user) {
    return <>{props.children}</>;
  }

  return <Navigate to="/login" replace />;
}
