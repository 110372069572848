import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImage } from "../api";
import { useAuth } from "../Component/AuthProvider";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid black",
  marginBottom: 8,
  marginRight: 8,
  width: "225px",
  height: "250px",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "225px",
  height: "250px",
};

const Previews = ({ files, setFiles }) => {
  const { user } = useAuth();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);
      let urls = await fetch(uploadImage, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "lvbkarna",
        }),
      });
      urls = await urls.json();
      const value = await fetch(urls.signURL, {
        method: "PUT",
        body: acceptedFiles[0],
      });
      setFiles(urls.url);
    },
  });

  return (
    <section>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {files.length ? (
          <aside style={thumbsContainer}>
            <div style={thumb} key={files.name}>
              <div style={thumbInner}>
                <img
                  src={files}
                  style={img}
                  alt="profile"
                  onLoad={() => {
                    URL.revokeObjectURL(files.preview);
                  }}
                />
              </div>
            </div>
          </aside>
        ) : (
          <button
            style={{
              width: "225px",
              height: "250px",
            }}
            className="bg-transparent text-black border border-black rounded-md text-base"
          >
            Add Image
          </button>
        )}
      </div>
    </section>
  );
};

export default Previews;
